<template>
    <LiefengContent>
        <template #title>{{ "栏目授权机构统计" }}</template>
        <template #toolsbarRight>
            <Button type="error" style="margin: 0 0 0 50px" @click="Export">导出报表</Button>
        </template>
        <template #contentArea>

            <LiefengTable
                ref="tab"
                :tableData="tableData"
                :talbeColumns="talbeColumns"
                :loading="loading"
                :fixTable="true"
                :hidePage="isHidePage"
                :curPage="page"
                :total="total"
                :page-size="pageSize"
                :pagesizeOpts="[50, 1000, 2000, 3000]"
                @hadlePageSize="hadlePageSize"
                :showIndex="false"
            ></LiefengTable>

            <!-- 社区空白栏目 -->
            <LiefengModal :title="'配置社区数'" width="700px" height="70vh" :value="blankStatus" @input="changeBlankStatus">
                <template v-slot:title>
                    <p style="font-size: 18px; font-weight: 700">配置社区数</p>
                    <Button type="primary" style="margin-left:20px" @click="exportBTable">导出报表</Button>
                </template>
                <template v-slot:contentarea>
                    <LiefengTable  ref="bTab" :tableData="bTableData" :talbeColumns="bTalbeColumns" :loading="bLoading" :hidePage="true"></LiefengTable>
                </template>
                <template v-slot:toolsbar>
                    <Button style="margin: 0 5px 0 13px" type="primary" @click="blankStatus = false">确定</Button>
                </template>
            </LiefengModal>
            <!-- 授权机构明细 -->
            <LiefengModal :title="'授权机构明细'" width="700px" height="70vh" :value="blankStatusOther" @input="changeBlankStatusOther">

                <template v-slot:contentarea>
                    <div style="margin-bottom:10px">
                        <Select v-model="dimensionId" style="width: 200px;margin-right:20px" placeholder="请选择维度" :label-in-value="true" filterable>
                            <Option v-for="(item, index) in orgList" :value="item.id" :key="index">{{ item.name }}</Option>
                        </Select>
                        <Input v-model="communityName" style="width: 200px; margin-right: 10px" placeholder="通过机构名称或编码查询"></Input>
                        <Button style="margin-right: 10px" type="primary" icon="ios-search" @click="search">查询</Button>
                        <Button style="margin-right: 10px" type="success" icon="ios-refresh" @click="reset">重置</Button>
                    </div>
                    <LiefengTable  ref="cTab" :tableData="cTableData" :talbeColumns="cTalbeColumns" :loading="cLoading" :hidePage="true"></LiefengTable>
                </template>
                <template v-slot:toolsbar>
                    <Button style="margin: 0 5px 0 13px" type="primary" @click="blankStatusOther = false">确定</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengCascaderCity from "./children/LiefengCascaderCity"
export default {
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
        LiefengCascaderCity,
    },
    data() {
        return {
            orgOnlineStatus: "", // 切换的状态
            level: 3, // 切换的级别
            columnName: 0, // 栏目数
            nowTime1: "", // 当前时间
            resetNum: 1, // 是否清空当前社区选择

            tableData: [],
            talbeColumns: [
                {
                    title:'序号',
                    key:"num",
                    width:"80",
                    align: "center",
                },
                {
                    title: "栏目名称",
                    key: "columnName",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "栏目编码",
                    key: "columnCode",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "配置社区数",
                    key: "communityNum",
                    minWidth: 100,
                    align: "center",
                    render:(h,params) =>{
                         return h(
                            "div",
                            {
                                style: {
                                    color: params.row.communityNum > 0 ? "blue" : 'black',
                                    cursor: "pointer",
                                    userSelect: "none",
                                    textAlign: "center"
                                },
                                on: {
                                    click: () => {
                                        this.changeBlankStatus(true,params)
                                       
                                    },
                                },
                            },
                            params.row.communityNum
                        )
                    },
                },
                {
                    title: "授权机构数",
                    key: "orgCodeScopeNum",
                    minWidth: 100,
                    align: "center",
                    render:(h,params) =>{
                         return h(
                            "div",
                            {
                                style: {
                                    color: params.row.orgCodeScopeNum > 0 ? "blue" : 'black',
                                    cursor: "pointer",
                                    userSelect: "none",
                                    textAlign: "center"
                                },
                                on: {
                                    click: () => {
                                        this.changeBlankStatusOther(true,params.row.id)
                                    },
                                },
                            },
                            params.row.orgCodeScopeNum
                        )
                    },
                },
            ],
            loading: false,
            menuCodeObj: {},

            cascaderList: [],

            // 栏目点击参数

            blankStatus: false,
            bTableData: [],
            bTalbeColumns: [
                {
                    title: "区",
                    key: "zoneName",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "街道",
                    key: "streetName",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "社区",
                    key: "communityName",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "路径",
                    key: "columnUrl",
                    minWidth: 100,
                    align: "center",
                },
            ],
            bLoading: false,
            nameUrl: "",
            clumnNum: 0,
            nowTime2: "",

            onlineType: "",

            disabled: false, // 是否禁用选择器
            isHidePage: true,

            page: 1,
            pageSize: 50,
            total: 0,

            allTableData: [], //暂存全部信息
            showCascader: true,

            blankStatusOther:false,
            cTableData:[],
            cTalbeColumns:[
                {
                    title: "机构",
                    key: "orgName",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "所属维度",
                    key: "dimensionName",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "机构编码",
                    key: "orgCode",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "服务范围",
                    key: "scopeLists",
                    minWidth: 100,
                    align: "center"
                },
            ],
            cLoading:false,
            dimensionId:'',
            communityName:'',
            orgList:[],
            selectId:''

        }
    },
    async created() {
        this.nowTime1 = this.$core.formatDate(new Date(new Date().getTime()), "yyyy-MM-dd")
        await this.getByMenuCode()
        await this.getList()
    },
    methods: {
        search(){
            this.getCList(this.selectId)
        },
        reset(){
            this.dimensionId = ''
            this.communityName = ''
            this.getCList(this.selectId)
        },
        async getOrgList(){
             await this.$get("/gx/pc/dimension/selectExceptOneByOemCode", {
            oemCode: window.top.vue.oemInfo.oemCode,
        })
            .then(res => {
                if (res.code == 200 && res.dataList) {
                    this.orgList = res.dataList.map((item, index) => {
                        return {
                            id: item.id,
                            name: item.name,
                        }
                    })
                    console.log(this.orgList);
                } else {
                    this.$Message.error({
                        background: true,
                        content: res.desc,
                    })
                }
            })
            .catch(err => {
                console.log(err)
                this.$Message.error({
                    background: true,
                    content: "获取数据失败，请联系管理员处理",
                })
            })
        },
        changeBlankStatusOther(status,id){
            this.blankStatusOther = status
            if(status && id && id != ''){
                this.selectId = id
                this.getCList(id)
                this.getOrgList()
            }
        },
        // 获取授权机构明细
        getCList(id){
            this.cLoading = true
            this.$get('/gateway/api/symanage/pc/orgManageColumn/selectOrgInfo',{
                id,
                dimensionId:this.dimensionId,
                communityName:this.communityName
            }).then(res=>{
                this.cLoading = false
                this.cTableData = res.dataList.map(item =>{
                    return{
                        ...item,
                        scopeLists:item.scopeList && item.scopeList.length ? item.scopeList.join(',') : ''
                    }
                })
            })
        },
        // 改变分页
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.tableData = []
            if(val.page == 1){
                this.tableData = JSON.parse(JSON.stringify(this.allTableData)).slice(0, this.page * this.pageSize)
            }else{
                this.tableData = JSON.parse(JSON.stringify(this.allTableData)).slice((this.page * this.pageSize) - (this.pageSize), this.page * this.pageSize)
            }
        },

        //获取列表
        async getList(val) {
            this.$get('/gateway/api/symanage/pc/orgManageColumn/selectLastDate').then(res=>{
                this.tableData = res.dataList.map((item,index) =>{
                    return{
                        ...item,
                        num:index + 1,
                    }
                })
            })
        },
        // 获取columnCode
        async getByMenuCode() {
            await this.$get("/old/api/pc/menu/selectMenuByMenuCode", {
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                menuCode: this.$core.getUrlParam("menuId"),
            }).then(res => {
                if (res.code == 200 && res.data) {
                    this.menuCodeObj = res.data
                }
            })
        },
        //导出栏目报表
        exportBTable(){
            this.$Modal.confirm({
                title: "温馨提示",
                content: "配置社区数",
                onOk: () => {
                    let tHeader = [
                        '区','街道','社区','路径'
                    ];
                    let filterVal = [
                        'zoneName',"streetName","communityName","columnUrl"
                    ];
                    let tableData = JSON.parse(JSON.stringify(this.bTableData));
                    this.$core.exportExcel(tHeader, filterVal, tableData, "配置社区数");
                },
            })
        },
        //导出报表
        Export() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "栏目授权机构的统计",
                onOk: () => {
                    let tHeader = [
                        '机构','所属维度','机构编码','服务范围'
                    ];
                    let filterVal = [
                        'orgName',"dimensionName","orgCode","scopeLists"
                    ];
                    let tableData = JSON.parse(JSON.stringify(this.tableData));
                    this.$core.exportExcel(tHeader, filterVal, tableData, "栏目授权机构的统计");
                    // this.$refs.tab.$refs.lftable.exportCsv({
                    //     filename: "栏目授权机构的统计",
                    // })
                },
            })
        },
        // 配置社区数
        getOrgNum(id){
            this.bLoading = true
            this.$get('/gateway/api/symanage/pc/orgManageColumn/selectCommunityInfo',{
                id
            }).then(res=>{
                this.bLoading = false
                this.bTableData = res.dataList
            })
        },
        // 获取空白栏目列表
        getBlankList(orgCode) {
            this.bLoading = true
            this.$get("/statistic/api/symanage/pc/columnTree/selectLayerNulByOrgCode", {
                orgCode,
            })
                .then(res => {
                    this.bLoading = false
                    if (res.code == 200 && res.dataList) {
                        this.nowTime2 = this.$core.formatDate(new Date(new Date().getTime()), "yyyy-MM-dd")
                        this.bTableData = res.dataList.map((item, index) => {
                            return {
                                num: index + 1,
                                ...item,
                            }
                        })
                        this.clumnNum = this.bTableData.length
                    } else {
                        this.nowTime2 = ""
                        this.$Message.error({
                            content: res.decs,
                            background: true,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.nowTime2 = ""
                    this.bLoading = false
                    this.$Message.error({
                        content: "获取空白栏目失败",
                        background: true,
                    })
                })
        },
        changeBlankStatus(val,params) {
            this.blankStatus = val
            if(val){
                 this.getOrgNum(params.row.id)
            }
        },
    },
}
</script>
    
<style scoped lang='less'>
/deep/#title {
    justify-content: space-between;
    display: flex;
    padding: 10px;
}
.readnum {
    display: inline;
    margin-left: 20px;
    font-size: 14px;
    font-weight: 700;
    li {
        display: inline;
        margin-left: 15px;
    }
    .click {
        text-decoration: underline;
        cursor: pointer;
    }
}
.readNum {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 20px;
    li {
        display: inline;
        margin-left: 15px;
    }
}
.ivu-form-item {
    margin-bottom: 5px;
    margin-right: 0px;
}
/deep/.ivu-table-sort {
    width: 26px;
    height: 13px;
    i {
        font-size: 27px;
    }
}
</style>